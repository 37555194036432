import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import ExampleCustomInput from '../../core/customCalender/customCalender';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import './../../assets/scss/user.scss';
import ApproveImg from 'assets/img/Approve.svg';
import RejectImg from 'assets/img/Reject.svg';
import ApprovedImg from 'assets/img/Approved.svg';
import RejectedImg from 'assets/img/rejected.svg';

export const DropdownComponent = ({
  title,
  selected,
  options,
  onSelect,
  customStyle
}) => {
  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle variant="falcon-default">
        {title}: {selected || 'all'}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={`py-2 ${customStyle ? 'dropdown-menu-custom' : ''}`}
      >
        {options.map(option => (
          <Dropdown.Item key={option.id} eventKey={option.value}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownComponent.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  customStyle: PropTypes.bool
};

DropdownComponent.defaultProps = {
  customStyle: false
};

export const SearchComponent = ({ searchTerm, onSearchChange }) => {
  return (
    <div className="search-container justify-content-start pointer">
      <FontAwesomeIcon icon="search" className="search-icon pointer" />
      <input
        className="search-content pointer"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={event => onSearchChange(event.target.value)}
      />
    </div>
  );
};

export const TableHeaderComponent = ({ columnNames }) => {
  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <th scope="col" key={index} className="slate-grey-fs-13-fw-600">
          {columnName}
        </th>
      ))}
    </tr>
  );
};

TableHeaderComponent.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const getStatusBackgroundColor = status => {
  switch (status) {
    case 'COMPLETED':
      return 'success';
    case 'CANCELLED':
      return 'danger';
    case 'APPROVED':
      return 'success';
    case 'REQUESTED':
      return 'warning';
    case 'REJECTED':
      return 'danger';
    default:
      return 'primary';
  }
};

export const PaginationComponent = ({
  prevPage,
  nextPage,
  isPrevDisabled,
  isNextDisabled
}) => {
  return (
    <div className="bottom-padding">
      <Flex className="justify-content-end">
        <Button size="sm" onClick={prevPage} disabled={isPrevDisabled}>
          Previous
        </Button>
        <Button
          size="sm"
          className="px-4 ms-2"
          onClick={nextPage}
          disabled={isNextDisabled}
        >
          Next
        </Button>
      </Flex>
    </div>
  );
};

PaginationComponent.propTypes = {
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired
};

export const CustomDatePicker = ({ selectedDate, onChange }) => {
  return (
    <DatePicker
      popperPlacement="bottom-start"
      selected={selectedDate}
      onChange={onChange}
      customInput={
        <ExampleCustomInput value={selectedDate} onChange={onChange} />
      }
      maxDate={new Date()}
      dateFormat="dd-MM-yyyy"
      locale="en"
    />
  );
};

CustomDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired
};

export const formatDate = dateTimeString => {
  if (!dateTimeString) return '--';
  const date = new Date(dateTimeString);
  return format(date, 'dd-MM-yyyy, hh:mm:ss a');
};

formatDate.propTypes = {
  dateTimeString: PropTypes.string
};

export const todayDate = () => {
  const now = new Date();
  const indianTime = new Date(
    now.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
  );

  const day = indianTime.getDate().toString().padStart(2, '0');
  const month = (indianTime.getMonth() + 1).toString().padStart(2, '0');
  const year = indianTime.getFullYear();

  return `${day}/${month}/${year}`;
};

export const toPascalWord = status => {
  if (typeof status === 'string') {
    switch (status.toUpperCase()) {
      case 'AVAILABLE':
        return 'Available';
      case 'NOT_AVAILABLE':
        return 'Not Available';
      case 'CHARGING':
        return 'Charging';
      case 'ASSIGNED':
        return 'Assigned';
      case 'CANCELLED':
        return 'Cancelled';
      case 'ONBOARD':
        return 'Onboard';
      case 'ARRIVED':
        return 'Arrived';
      case 'COMPLETED':
        return 'Completed';
      case 'READY_TO_ASSIGN':
        return 'Ready to assigned';
      case 'IN_RIDE':
        return 'Inride';
      case 'REACHING_THE_HUB':
        return 'Reaching the hub';
      case 'ONLINE':
        return 'Online';
      case 'OFFLINE':
        return 'Offline';
      case 'WAITING_FOR_CAB_ASSIGN':
        return 'Waiting for cab assign';
      default:
        return 'Primary';
    }
  }
  return 'Primary';
};

export const dateStartsWithDay = date => {
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return formattedDate;
};

export const goBack = () => {
  window.history.back();
};

export const getStatusKey = statusParam => {
  switch (statusParam) {
    case 'Ready to be assigned':
      return 'READY_TO_ASSIGN';
    case 'In ride':
      return 'IN_RIDE';
    case 'Offline':
      return 'OFFLINE';
    case 'Assigned':
      return 'ASSIGNED';
    default:
      return 'ONLINE';
  }
};
export const getRideStatus = statusParam => {
  switch (statusParam) {
    case 'Completed':
      return 'COMPLETED';
    case 'Cancelled':
      return 'CANCELLED';
    default:
      return 'COMPLETED,CANCELLED';
  }
};

export const getPayMentMethod = statusParam => {
  switch (statusParam) {
    case 'CASH':
      return 'CASH';
    case 'UPI':
      return 'UPI';
    default:
      return 'CASH,UPI';
  }
};
export const getVehicleStatus = statusParam => {
  switch (statusParam) {
    case 'Available':
      return 'AVAILABLE';
    case 'Not Available':
      return 'NOT_AVAILABLE';
    case 'Charging':
      return 'CHARGING';
    default:
      return 'AVAILABLE,NOT_AVAILABLE,CHARGING';
  }
};
export const DocumentStatusButton = ({
  document,
  handleApprove,
  handleReject
}) => {
  return (
    <div className="d-flex">
      {document?.documentStatus === 'APPROVED' ? (
        <button type="button" style={{ background: 'none', border: 'none' }}>
          <img src={ApprovedImg} alt="Approved" />
        </button>
      ) : document?.documentStatus === 'REJECTED' ? (
        <button type="button" style={{ background: 'none', border: 'none' }}>
          <img src={RejectedImg} alt="Rejected" />
        </button>
      ) : document?.documentStatus === 'UNDER_VERIFICATION' ? (
        <>
          <button
            type="button"
            onClick={() => handleReject(document)}
            style={{ background: 'none', border: 'none' }}
          >
            <img src={RejectImg} alt="Reject" />
          </button>
          <button
            type="button"
            onClick={() => handleApprove(document)}
            style={{ background: 'none', border: 'none' }}
          >
            <img src={ApproveImg} alt="Approve" />
          </button>
        </>
      ) : null}
    </div>
  );
};
