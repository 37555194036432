export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
      exact: true
    },
    {
      name: 'Manage Drivers',
      active: true,
      icon: 'user',
      children: [
        {
          name: 'Approved Drivers',
          to: '/manage-drivers/approved-drivers',
          active: true
        },
        {
          name: 'Unapproved Drivers',
          to: '/manage-drivers/unapproved-drivers',
          active: true
        }
      ]
    },
    {
      name: 'Ride History',
      active: true,
      icon: 'clock',
      to: '/manage-rides'
    }
  ]
};

export default [dashboardRoutes];
