import React from 'react';
import OtpValidationForm from 'components/authentication/OtpValidationForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const OtpValidation = () => {
  return (
    <AuthCardLayout>
      <div className="text-center">
        <h5 className="mb-0"> Verify otp</h5>
        <small>Please enter the code sent to your email</small>
        <OtpValidationForm />
      </div>
    </AuthCardLayout>
  );
};

export default OtpValidation;
