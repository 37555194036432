import Flex from 'components/common/Flex';
import {
  DropdownComponent,
  getStatusKey,
  PaginationComponent,
  TableHeaderComponent,
  toPascalWord
} from 'components/commonComponent/commonComponents';
import React, { useMemo, useState, useEffect } from 'react';
import { Card, Dropdown, Table } from 'react-bootstrap';
import './../../../../assets/scss/user.scss';
import Loader from 'core/loader/loader';
import HubManagerService from 'core/service/HubManagerService';
import SubtleBadge, { chargeByColor } from 'components/common/SubtleBadge';
import CardDropdown from 'components/common/CardDropdown';
import style from './OnlineVehicleComponent.module.scss';
import { SearchAPIComponent } from 'components/common/SearchAPIComponent';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';

const OnlineVehiclesComponent = () => {
  const ENTRIES_PER_PAGE = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [showNoOnlineVehiclesDiv, setNoOnlineVehiclesDiv] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('Charging High to low');
  const [status, setStatus] = useState('All');
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const hubManagerService = new HubManagerService();
  const columnNames = [
    'Vehicle Number',
    'Driver Id',
    'Charge',
    'Status',
    'Action'
  ];

  useEffect(() => {
    if (!searchTerm) {
      fetchOnlineVehicles();
    }
  }, [currentPage, status, sort, searchTerm]);

  const fetchOnlineVehicles = () => {
    setLoading(true);
    setNoOnlineVehiclesDiv(true);
    const params = {
      pageIndex: currentPage,
      pageSize: ENTRIES_PER_PAGE,
      status: getStatusKey(status).toUpperCase(),
      sort: sort
    };
    hubManagerService
      .fetchOnlineVehicles(params)
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setData(response.data.responseBody.searchResult);
          setTotalPages(
            Math.ceil(response.data.responseBody.totalItems / ENTRIES_PER_PAGE)
          );
        }
      })
      .catch(error => {
        handleErrorResponse('fetchOnlineVehicles', error.response);
        setNoOnlineVehiclesDiv(false);
      });
  };

  const handleSearchResult = result => {
    if (result !== null) {
      const vehicles = result.responseBody.searchResult;
      setData(vehicles);
      setTotalPages(
        Math.ceil(result.responseBody.totalItems / ENTRIES_PER_PAGE)
      );
      setNoOnlineVehiclesDiv(true);
    } else {
      setNoOnlineVehiclesDiv(false);
    }
  };

  const handleStatusChange = selectedStatus => {
    setStatus(selectedStatus === 'All' ? 'All' : selectedStatus);
  };

  const handleSortChange = selectedSort => {
    setSort(
      selectedSort === 'Charging High to low'
        ? 'Charging High to low'
        : selectedSort
    );
  };

  const filteredVehicles = useMemo(() => {
    const normalizedSearchTerm = searchTerm.toLowerCase().replace(/\s+/g, '');
    return data.filter(vehicle => {
      const vehicleNumber = vehicle.vehicleNumber
        .toLowerCase()
        .replace(/\s+/g, '');
      const driverId = vehicle.duid.toLowerCase();
      return (
        vehicleNumber.includes(normalizedSearchTerm) ||
        driverId.includes(normalizedSearchTerm)
      );
    });
  }, [data]);

  const TableRow = ({ data }) => (
    <tr className="align-middle">
      <td className="col-fixed-width blue-text">{data.vehicleNumber}</td>
      <td className="col-fixed-width blue-text">{data.duid}</td>
      <td>
        <SubtleBadge pill bg={chargeByColor(data.vehicleCharge)}>
          {data.vehicleCharge + '%'}
        </SubtleBadge>
      </td>
      <td className={`col-fixed-width green-text`}>
        {toPascalWord(data.driverStatus)}
      </td>
      <td>
        <CardDropdown variant="falcon-default">
          <div className="py-2">
            <Dropdown.Item href="#!">View</Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );

  const renderVehicles = () => {
    return filteredVehicles.map(entry => (
      <TableRow data={entry} key={entry.vehicleNumber} />
    ));
  };

  const driverStatusOptions = [
    { id: 1, value: 'All', label: 'All' },
    { id: 2, value: 'Ready to be assigned', label: 'Ready to be assigned' },
    { id: 3, value: 'Assigned', label: 'Assigned' },
    { id: 4, value: 'In ride', label: 'In ride' },
    { id: 5, value: 'Reaching the hub', label: 'Reaching the hub' }
  ];
  const sortOptions = [
    { id: 1, value: 'Charging High to low', label: 'Charging High to low' },
    { id: 2, value: 'Charging Low to high', label: 'Charging Low to high' }
  ];

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const isNextDisabled = currentPage >= totalPages - 1;
  const isPrevDisabled = currentPage === 0;

  return (
    <div className="py-3">
      <Header
        breadcrumb="Manage Vehicles/Online Vehicles"
        title="Online Vehicles"
      />

      <Card
        className={` ${!showNoOnlineVehiclesDiv ? style['shadow-none'] : ''}`}
      >
        <div className="bottom-padding">
          <Flex className="justify-content-between flex-wrap">
            <SearchAPIComponent
              searchTerm={searchTerm}
              onSearchChange={setSearchTerm}
              sort={sort}
              status={status}
              onSearchResult={handleSearchResult}
              pageIndex={currentPage}
              pageSize={ENTRIES_PER_PAGE}
              service={'searchOnlineCabs'}
              loading={setLoading}
            />

            <Flex className="justify-content-end">
              <div className="me-3">
                <DropdownComponent
                  title="Sort"
                  selected={sort}
                  options={sortOptions}
                  onSelect={handleSortChange}
                  customStyle={true}
                />
              </div>
              <DropdownComponent
                title="Status"
                selected={status}
                options={driverStatusOptions}
                onSelect={handleStatusChange}
              />
            </Flex>
          </Flex>
        </div>

        {showNoOnlineVehiclesDiv ? (
          <Card.Body className="p-0">
            <div className="table-responsive">
              <Table className="table table-striped table-hover">
                <thead className="header-padding">
                  <TableHeaderComponent columnNames={columnNames} />
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={columnNames.length} className="text-center">
                        <Loader loading={loading} />
                      </td>
                    </tr>
                  )}
                  {!loading && renderVehicles()}
                </tbody>
              </Table>
            </div>
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isPrevDisabled}
            />
          </Card.Body>
        ) : (
          <div className="bg-color">
            <div className="d-flex justify-content-center">
              <img
                src={require('./../../../../assets/img/No-vehicles-found.png')}
                alt="No vehicles"
              />
            </div>
            <p className="no-content-text text-center">No vehicles</p>
          </div>
        )}
      </Card>
    </div>
  );
};
export default OnlineVehiclesComponent;
