import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { OtpService } from 'core/service/OtpService';

const OtpValidationForm = () => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const otpService = new OtpService();

  const handleSubmit = async e => {
    e.preventDefault();
    const email = sessionStorage.getItem('forgotPasswordEmail');

    if (email && code) {
      try {
        const { data } = await otpService.verifyForgotPasswordOtp(email, code);

        if (data.responseCode === 200) {
          toast.success('Entered OTP is valid', { theme: 'colored' });
          navigate('/password-reset');
        } else {
          toast.error(data.responseMessage, { theme: 'colored' });
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.responseMessage || error.message;
        toast.error(`Error verifying OTP: ${errorMessage}`, {
          theme: 'colored'
        });
      }
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder="Enter OTP here"
          value={code}
          name="otp"
          onChange={({ target }) => setCode(target.value)}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!code}>
          Verify OTP
        </Button>
      </Form.Group>
    </Form>
  );
};

export default OtpValidationForm;
