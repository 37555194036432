import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import DataTransferService from 'core/service/dataTransferService';
import { DashBoardService } from 'core/service/dashBoardService';
const LoginForm = ({ hasLabel }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const dashBoardService = new DashBoardService();
  const navigate = useNavigate();
  const dataTransferService = new DataTransferService();

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    dashBoardService
      .login(formData)

      .then(data => {
        toast.success(`Logged in as ${formData.email}`, {
          theme: 'colored'
        });
        dataTransferService.setDashboardToken(data.data.token);
        navigate('/');
      })
      .catch(error => {
        toast.error(
          `${'login'} ${error.response.data.responseCode} ${
            error.response.data.responseMessage
          }`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="col pt-2 pb-2 fs-13">Email Address</div>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Row className="align-items-center">
        <Col>
          <div className="col pt-2 fs-13 pb-2">Password</div>
        </Col>
        <Col xs="auto">
          <Link className="fs-13 mb-0" to="/forgot-password">
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 fs-14 w-100"
          disabled={!formData.email || !formData.password || isLoading}
        >
          {isLoading ? 'Logging in...' : 'Log in'}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
