import { Button, Form, Card, Col, Row } from 'react-bootstrap';
import HubManagerService from 'core/service/HubManagerService';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../../../assets/scss/user.scss';
import Loader from 'core/loader/loader';
import { toast } from 'react-toastify';
import Header from 'components/common/Header';

const VehicleListComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    vehicleNumber: '',
    vehicleCharge: '',
    vehicleStatus: '',
    reason: ''
  });
  const [loading, setLoading] = useState(false);
  const hubManagerService = new HubManagerService();
  const queryParams = new URLSearchParams(location.search);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    setFormData({
      vehicleNumber: queryParams.get('vehicleNumber') || '',
      vehicleCharge: queryParams.get('vehicleCharge') || '',
      vehicleStatus: queryParams.get('vehicleStatus'),
      reason: ''
    });
  }, [location.search]);

  const handleSubmit = e => {
    e.preventDefault();
    if (formData.vehicleCharge > 100) {
      toast.error('Vehicle charge cannot exceed 100');
      return;
    }
    setLoading(true);
    hubManagerService
      .editVehicleDetail(formData)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(`${response.data.responseMessage}`);
          navigate('/manage-vehicles/hub-vehicles');
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb="Manage Drivers/Hub Vehicles/Edit"
        title="Edit Vehicles"
      />

      <Card>
        <h5 className="ps-3 py-3">Vehicle details</h5>
        <Card.Body className="bg-color-gray">
          <Form className="form" onSubmit={handleSubmit}>
            <div className="item-pair">
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <p className="pb-2 fs-13-fw-500">Vehicle Number</p>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <p className="pb-2 fs-13-fw-500">Status</p>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6}>
                  <input
                    className="form-control"
                    name="vehicleNumber"
                    placeholder="Enter Vehicle Number"
                    value={formData.vehicleNumber}
                    readOnly
                  />
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <select
                    className="form-select"
                    name="vehicleStatus"
                    value={formData.vehicleStatus}
                    onChange={handleInputChange}
                  >
                    <option value="AVAILABLE">Available</option>
                    <option value="NOT_AVAILABLE">Not Available</option>
                    <option value="CHARGING">Charging</option>
                  </select>
                </Col>
              </Row>

              <Row className="pt-4">
                <Col lg={6} md={6} sm={6}>
                  <p className="pb-2 fs-13-fw-500">Charge</p>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <p className="pb-2 fs-13-fw-500">Reason</p>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6}>
                  <input
                    className="form-control"
                    name="vehicleCharge"
                    placeholder="Enter Charge"
                    value={formData.vehicleCharge}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <input
                    className="form-control"
                    name="reason"
                    placeholder="Enter Reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>

              <div className="pt-5 pb-3 d-flex justify-content-center">
                {loading && <Loader loading={loading} />}
                {!loading && (
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary savebutton rounded-pill"
                    disabled={!formData.vehicleCharge}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default VehicleListComponent;
