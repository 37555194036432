import ExampleCustomInput from './../../../../core/customCalender/customCalender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Dropdown } from 'react-bootstrap';
import HubManagerService from 'core/service/HubManagerService';
import React, { useState, useEffect } from 'react';
import CardDropdown from 'components/common/CardDropdown';
import SubtleBadge from 'components/common/SubtleBadge';
import './../changeVehicle/ChangeVehicleComponent.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../../assets/scss/user.scss';
import Flex from 'components/common/Flex';
import DatePicker from 'react-datepicker';
import Loader from 'core/loader/loader';
import { format } from 'date-fns';
import NoChangeRequestsImage from 'assets/img/change-vehicle.png';
import {
  PaginationComponent,
  dateStartsWithDay,
  getStatusBackgroundColor
} from 'components/commonComponent/commonComponents';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';

const ChangeVehicle = () => {
  const hubManagerService = new HubManagerService();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const columnNames = [
    'Driver ID',
    'Driver Name',
    'Vehicle Number',
    'Reason',
    'Requested At',
    'Status',
    'New Vehicle',
    'Action'
  ];

  const ENTRIES_PER_PAGE = 10;

  const renderTableHeaders = () => (
    <tr>
      {columnNames.map(columnName => (
        <th key={columnName} className="col-fixed-width">
          {columnName}
        </th>
      ))}
    </tr>
  );

  const statusLabels = {
    APPROVED: 'Approved',
    REQUESTED: 'Requested',
    REJECTED: 'Rejected'
  };

  const TableRow = ({ data }) => {
    console.log(data);
    const status = data.changeVehicles.changeVehicleStatus;

    const getNewVehicleContent = () => {
      if (status === 'REJECTED') {
        return data.previousVehicleDetails
          ? data.previousVehicleDetails.vehicleNumber
          : '-';
      } else if (status === 'REQUESTED') {
        return <span className="centered-dash">--</span>;
      } else if (data.approvedVehicleDetails) {
        return data.approvedVehicleDetails.vehicleNumber;
      } else {
        return '';
      }
    };

    const formatDate = dateTimeString => {
      if (!dateTimeString) return '--';
      const date = new Date(dateTimeString);
      return format(date, 'dd-MM-yyyy, hh:mm:ss a');
    };

    const getPreviousVehicleNumber = () => {
      if (status === 'REQUESTED') {
        return data.activeVehicleDetails
          ? data.activeVehicleDetails.vehicleNumber
          : '';
      }
      return data.previousVehicleDetails
        ? data.previousVehicleDetails.vehicleNumber
        : '';
    };

    return (
      <tr className="align-middle">
        <td className="col-fixed-width">
          {data.driver?.duid || data.drivers.duid}
        </td>
        <td className="col-fixed-width">{data.driverDetails.firstName}</td>
        <td className="col-fixed-width">{getPreviousVehicleNumber()}</td>
        <td className="col-fixed-width">{data.changeVehicles.reason}</td>
        <td className="col-fixed-width">
          {formatDate(data.changeVehicles.genericDetails.created_time)}
        </td>
        <td>
          <SubtleBadge pill bg={getStatusBackgroundColor(status)}>
            {statusLabels[status] || status}
          </SubtleBadge>
        </td>
        <td className="col-fixed-width">{getNewVehicleContent()}</td>
        <td className="action">
          <CardDropdown drop="end">
            <div className="py-2">
              <Dropdown.Item href="#!">View</Dropdown.Item>
            </div>
          </CardDropdown>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, selectedDate, selectedStatus, searchTerm]);

  const fetchData = () => {
    const statusParam =
      selectedStatus === 'All'
        ? ['APPROVED', 'REQUESTED', 'REJECTED']
        : [selectedStatus];
    const statusQueryString = statusParam.join(',');

    setLoading(true);
    const startIndex = ((currentPage - 1) / ENTRIES_PER_PAGE) * 10;

    const response = searchTerm
      ? hubManagerService.searchChangeVehicles(
          startIndex,
          ENTRIES_PER_PAGE,
          statusQueryString,
          dateStartsWithDay(selectedDate),
          searchTerm
        )
      : hubManagerService.fetchChangeVehicleDetails(
          startIndex,
          ENTRIES_PER_PAGE,
          statusQueryString,
          dateStartsWithDay(selectedDate)
        );

    response
      .then(res => {
        if (res.data.responseCode === 200) {
          setData(res.data.responseBody);
        } else {
          setData(null);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setData(null);
        handleErrorResponse('fetchChangeVehicle', err.response);
      });
  };

  const totalPages = Math.ceil(data?.totalItems / ENTRIES_PER_PAGE) || 1;
  const isNextDisabled = currentPage >= totalPages;
  const isPrevDisabled = currentPage === 1;

  const nextPage = () => {
    if (!isNextDisabled) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (!isPrevDisabled) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderSearchResults = () => {
    if (!data || !data.searchResult || data.searchResult.length === 0) {
      return null;
    }
    return data.searchResult.map((entry, index) => (
      <TableRow data={entry} key={index} />
    ));
  };

  const handleStatusChange = selectedStatus => {
    setSelectedStatus(selectedStatus === 'All' ? 'All' : selectedStatus);
    setCurrentPage(1);
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb="Manage Vehicles/Change Vehicle"
        title="Change Vehicle"
      />

      <div>
        <Card>
          <div className="bottom-padding">
            <Flex className="justify-content-between flex-wrap">
              <div className="search-container justify-content-start">
                <FontAwesomeIcon icon="search" className="search-icon" />
                <input
                  className="search-content"
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
              <Flex className="justify-content-end">
                <div className="mx-3">
                  <DatePicker
                    popperPlacement="bottom-start"
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    customInput={
                      <ExampleCustomInput
                        value={selectedDate}
                        onChange={event => setSelectedDate(event.target.value)}
                      />
                    }
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    locale="en"
                  />
                </div>
                <Dropdown onSelect={handleStatusChange}>
                  <Dropdown.Toggle variant="falcon-default">
                    Status: {statusLabels[selectedStatus] || 'All'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                    <Dropdown.Item eventKey="APPROVED">Approved</Dropdown.Item>
                    <Dropdown.Item eventKey="REQUESTED">
                      Requested
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="REJECTED">Rejected</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Flex>
            </Flex>
          </div>
        </Card>
      </div>

      {loading ? (
        <div className="text-center my-4">
          <Loader loading={loading} />
        </div>
      ) : (
        <>
          {data && data.searchResult && data.searchResult.length > 0 ? (
            <Card>
              <Table striped responsive hover>
                <thead className="header-padding">{renderTableHeaders()}</thead>
                <tbody>{renderSearchResults()}</tbody>
              </Table>
              <PaginationComponent
                prevPage={prevPage}
                nextPage={nextPage}
                isNextDisabled={isNextDisabled}
                isPrevDisabled={isPrevDisabled}
              />
            </Card>
          ) : (
            <div className="text-center my-4">
              <img src={NoChangeRequestsImage} alt="No change requests" />
              <p className="mt-3 no-content-text">
                No Requests found for change vehicle
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChangeVehicle;
