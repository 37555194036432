import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Table, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Loader from 'core/loader/loader';
import { useNavigate } from 'react-router-dom';
import driverNotFound from 'assets/img/drivers-not-found-svg.svg';
import {
  getStatusKey,
  PaginationComponent
} from 'components/commonComponent/commonComponents';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import Header from 'components/common/Header';
import { DashBoardService } from 'core/service/dashBoardService';
import { DropdownComponent } from 'components/commonComponent/commonComponents';

const ApprovedDriversComponent = () => {
  const dashBoardService = new DashBoardService();
  const [drivers, setDrivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('Online');

  const ENTRIES_PER_PAGE = 10;

  const columnNames = [
    'Driver Id',
    'Driver Name',
    'Phone Number',
    'Gender',
    'Action'
  ];

  const renderTableHeaders = () => {
    return (
      <tr>
        {columnNames.map((columnName, index) => (
          <th key={index} className="col-fixed-width">
            {columnName}
          </th>
        ))}
      </tr>
    );
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      fetchApprovedDrivers();
    } else {
      searchDrivers();
    }
  }, [currentPage, searchTerm, selectedStatus]);

  const handleEditClick = (event, data) => {
    event.preventDefault();
    navigate(
      `/manage-drivers/approved-drivers/ride-history?duid=${encodeURIComponent(
        data.duid
      )}`
    );
  };

  const TableRow = ({ data }) => {
    return (
      <tr className="align-middle">
        <td className="col-fixed-width">
          <a href="#" onClick={event => handleEditClick(event, data)}>
            {data.duid}
          </a>
        </td>
        <td className="col-fixed-width">{data.firstName}</td>
        <td className="col-fixed-width">{data.mobileNumber}</td>
        <td className="col-fixed-width">{data.gender}</td>
        <td>
          <CardDropdown drop="end">
            <Dropdown.Item href="#">view</Dropdown.Item>
          </CardDropdown>
        </td>
      </tr>
    );
  };

  const fetchApprovedDrivers = () => {
    setLoading(true);
    console.log(
      'Calling fetchApprovedDrivers with page:',
      currentPage,
      'and entries per page:',
      ENTRIES_PER_PAGE
    );

    dashBoardService
      .approvedDrivers(
        currentPage,
        ENTRIES_PER_PAGE,
        getStatusKey(selectedStatus)
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          console.log('Received response body:', responseBody);

          setDrivers(responseBody.searchResult || []);
          setTotalPages(Math.ceil(responseBody.totalItems / ENTRIES_PER_PAGE));
        }
      })
      .catch(error => {
        setLoading(false);
        setDrivers([]);
        console.error('Error in fetchApprovedDrivers:', error);
        handleErrorResponse('fetchDrivers', error.response);
      });
  };

  const searchDrivers = () => {
    setLoading(true);
    dashBoardService
      .searchApprovedDrivers(
        currentPage,
        ENTRIES_PER_PAGE,
        getStatusKey(selectedStatus),
        searchTerm
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const responseBody = response.data.responseBody;
          setDrivers(responseBody.searchResult || []);
          setTotalPages(Math.ceil(responseBody.totalItems / ENTRIES_PER_PAGE));
        }
      })
      .catch(error => {
        setLoading(false);
        setDrivers([]);
        handleErrorResponse('searchDrivers', error.response);
      });
  };

  const renderCustomers = () => {
    if (!Array.isArray(drivers) || drivers.length === 0) {
      return null;
    }
    return drivers.map((entry, index) => <TableRow data={entry} key={index} />);
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const isNextDisabled = currentPage >= totalPages - 1;
  const isPrevDisabled = currentPage === 0;

  const handleStatusChange = selectedStatus => {
    setSelectedStatus(selectedStatus === 'Online' ? 'Online' : selectedStatus);
  };
  const StatusOptions = [
    { id: 1, value: 'Online', label: 'Online' },
    { id: 2, value: 'Offline', label: 'Offline' },
    { id: 3, value: 'Ready to be assigned', label: 'Ready to be assigned' },
    { id: 4, value: 'In ride', label: 'In ride' }
  ];
  return (
    <div className="py-3">
      <Header
        breadcrumb="Dashboard/Manage Drivers/Approved Drivers"
        title="Approved Drivers"
      />

      <Card>
        <div className=" p-3">
          <Flex className="justify-content-between">
            <div className="search-container justify-content-start pointer">
              <FontAwesomeIcon icon="search" className="search-icon pointer" />
              <input
                className="search-content pointer"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <Flex className="justify-content-end">
              <DropdownComponent
                title="Status"
                selected={selectedStatus}
                options={StatusOptions}
                onSelect={handleStatusChange}
              />
            </Flex>
          </Flex>
        </div>
      </Card>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {drivers.length === 0 ? (
            <div className="text-center no-content-text">
              <img
                className="w-50 h-25"
                src={driverNotFound}
                alt="driversNotFound"
              />
              <p>No Drivers Found</p>
            </div>
          ) : (
            <Card>
              <Card.Body className="p-0">
                <Table striped responsive hover>
                  <thead className="header-padding">
                    {renderTableHeaders()}
                  </thead>
                  <tbody>{renderCustomers()}</tbody>
                </Table>
                <PaginationComponent
                  prevPage={prevPage}
                  nextPage={nextPage}
                  isNextDisabled={isNextDisabled}
                  isPrevDisabled={isPrevDisabled}
                />
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ApprovedDriversComponent;
