import DriverDetails from './approvedDriverDetails';
import RideHistory from './rideHistory';
import Tabs from 'react-bootstrap/Tabs';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Header from 'components/common/Header';
import { useNavigate, useLocation } from 'react-router-dom';

function Test() {
  const [activeTab, setActiveTab] = useState('rideHistory');
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = tabKey => {
    setActiveTab(tabKey);
    const searchParams = new URLSearchParams(location.search);
    const duid = searchParams.get('duid');

    if (tabKey === 'rideHistory') {
      navigate(`/manage-drivers/approved-drivers/ride-history?duid=${duid}`);
    } else if (tabKey === 'driverDetails') {
      navigate(`/manage-drivers/approved-drivers/driver-details?duid=${duid}`);
    }
  };

  const getTitle = () => {
    switch (activeTab) {
      case 'rideHistory':
        return 'Ride History';
      case 'driverDetails':
        return 'Driver Details';
      default:
        return '';
    }
  };

  return (
    <div className="py-3">
      <Header
        breadcrumb={`Dashboard/Manage Drivers/Approved Drivers/${getTitle()}`}
        title="Approved Drivers"
      />

      <div className="tab-content">
        <Tabs defaultActiveKey="rideHistory" onSelect={handleTabChange}>
          <Tab eventKey="rideHistory" title="Ride History">
            <RideHistory />
          </Tab>
          <Tab eventKey="driverDetails" title="Driver Details">
            <DriverDetails />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Test;
