import { Appconfig } from 'core/config/app.config';
import axiosInstance from 'core/tokenInterceptor/AxiosInstance';

export class DashBoardService {
  constructor() {
    this.Api = Appconfig.getAPIURI() + 'dashboard';
  }

  login(formData) {
    const body = JSON.stringify(formData);
    return axiosInstance.post(this.Api + '/login', body);
  }

  forgotPassword(email) {
    const url = `${this.Api}/forgot-password?email=${encodeURIComponent(
      email
    )}`;
    return axiosInstance.post(url);
  }

  resetPassword(email, password) {
    const url = `${this.Api}/reset-password`;
    const payload = { email, password };
    return axiosInstance.post(url, payload);
  }

  searchUnapprovedDrivers(duid, pageIndex, pageSize) {
    const params = {
      duid,
      pageIndex,
      pageSize
    };

    return axiosInstance.get(this.Api + '/search-unapproved-drivers', {
      params: params
    });
  }
  fetchDriverDetails(duid) {
    const params = {
      duid
    };
    return axiosInstance.get(this.Api + '/fetch-driver-details', {
      params: params
    });
  }
  l̥;
  fetchUnapprovedDrivers(pageIndex, pageSize) {
    const params = {
      pageIndex,
      pageSize
    };
    return axiosInstance.get(this.Api + '/unapproved-drivers', {
      params: params
    });
  }
  approvedDrivers(pageIndex, pageSize, driverStatusMode) {
    const params = {
      pageIndex,
      pageSize,
      driverStatusMode
    };
    return axiosInstance.get(this.Api + '/approved-drivers', {
      params: params
    });
  }
  searchApprovedDrivers(pageIndex, pageSize, driverStatusMode, duid) {
    const params = {
      pageIndex,
      pageSize,
      driverStatusMode,
      duid
    };
    console.log(params);
    return axiosInstance.get(this.Api + '/search-approved-drivers', {
      params: params
    });
  }
  getCount() {
    return axiosInstance.get(this.Api + '/get-count');
  }
  fetchDashboardRideHistory(
    pageIndex,
    pageSize,
    rideStatus,
    paymentMethod,
    date
  ) {
    const params = {
      pageIndex,
      pageSize,
      date,
      rideStatus,
      paymentMethod
    };
    return axiosInstance.get(this.Api + '/fetch-dashboard-ride-history', {
      params: params
    });
  }

  fetchDriverRideHistory(
    duid,
    pageIndex,
    pageSize,
    rideStatus,
    paymentMethod,
    date
  ) {
    const params = {
      duid,
      pageIndex,
      pageSize,
      rideStatus,
      paymentMethod,
      date
    };

    return axiosInstance.get(this.Api + '/fetch-driver-ride-history', {
      params: params
    });
  }

  searchDriverRideHistory(params, ruid) {
    return axiosInstance.get(this.Api + `/search-driver-ride-history/${ruid}`, {
      params
    });
  }

  searchDashboardRideHistory(pageIndex, pageSize, searchTerm, searchType) {
    const params = {
      pageIndex,
      pageSize,
      ruid: '',
      duid: '',
      vehicleNumber: '',
      mobileNumber: ''
    };
    if (searchType === 'ruid') {
      params.ruid = searchTerm;
    } else if (searchType === 'duid') {
      params.duid = searchTerm;
    } else if (searchType === 'vehicleNumber') {
      params.vehicleNumber = searchTerm;
    } else if (searchType === 'user_mobileNumber') {
      params.mobileNumber = searchTerm;
    }
    return axiosInstance.get(`${this.Api}/search-dashboard-ride-history`, {
      params
    });
  }
  fetchRideCoordinatesList(ruid) {
    const params = {
      ruid
    };
    return axiosInstance.get(this.Api + '/fetch-co-ordinates-list', {
      params: params
    });
  }

  updateDocumentStatus(duid, body) {
    const params = {
      duid
    };
    return axiosInstance.put(`${this.Api}/update-document-status`, body, {
      params: params
    });
  }
}
