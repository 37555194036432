import React from 'react';
import RideHistory from './RideHistory';
import { Tab, Tabs } from 'react-bootstrap';
import './../../../assets/scss/user.scss';
import Header from 'components/common/Header';

const ManageRides = () => {
  return (
    <div className="py-3">
      <Header breadcrumb="Dashboard/Ride History" title="Rides" />

      <div className="tab-content">
        <Tabs defaultActiveKey="rideHistory">
          <Tab
            eventKey="rideHistory"
            title={
              <span className="color-charcoal-fs-19-fw-500">Ride History</span>
            }
          >
            <RideHistory />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ManageRides;
