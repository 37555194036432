import DataTransferService from 'core/service/dataTransferService';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

class LoginGuard extends React.Component {
  constructor(props) {
    super(props);
    this.dataTransferService = new DataTransferService();
  }

  isAuthenticated() {
    const dashboardToken = this.dataTransferService.getDashboardToken();
    return !!dashboardToken;
  }

  render() {
    const { element: Element, location, ...rest } = this.props;
    const authenticated = this.isAuthenticated();

    if (
      authenticated &&
      (location.pathname === '/login' || location.pathname === '/register')
    ) {
      return <Navigate to="/" replace />;
    }

    return <Element {...rest} />;
  }
}

LoginGuard.propTypes = {
  element: PropTypes.elementType.isRequired,
  location: PropTypes.object.isRequired
};

const LoginGuardWrapper = props => {
  const location = useLocation();
  return <LoginGuard {...props} location={location} />;
};

export default LoginGuardWrapper;
