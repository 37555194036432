import { Appconfig } from 'core/config/app.config';
import axiosInstance from 'core/tokenInterceptor/AxiosInstance';

export class OtpService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'otp';
  }

  verifyForgotPasswordOtp(email, code) {
    const url = `${
      this.Api
    }/verify-forgot-password-otp?email=${encodeURIComponent(email)}`;
    return axiosInstance.post(url, { code });
  }
}
