import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DashBoardService } from 'core/service/dashBoardService';

const ForgetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dashBoardService = new DashBoardService();

  const handleSubmit = async e => {
    e.preventDefault();
    if (email) {
      try {
        const response = await dashBoardService.forgotPassword(email);
        const { responseCode, responseMessage } = response.data;

        if (responseCode === 200) {
          sessionStorage.setItem('forgotPasswordEmail', email);
          toast.success(`OTP sent successfully to ${email}`, {
            theme: 'colored'
          });
          navigate('/otp-validation');
        } else {
          toast.error(`Error: ${responseMessage}`, { theme: 'colored' });
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.responseMessage;
        toast.error(`Error: ${errorMessage}`, { theme: 'colored' });
      }
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder="Email address"
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send OTP
        </Button>
      </Form.Group>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
