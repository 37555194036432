import DataTransferService from 'core/service/dataTransferService';
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

class AuthGuard extends React.Component {
  constructor(props) {
    super(props);
    this.dataTransferService = new DataTransferService();
  }

  isAuthenticated() {
    const dashboardToken = this.dataTransferService.getDashboardToken();
    return !!dashboardToken;
  }

  render() {
    const { element: Element, ...rest } = this.props;
    const authenticated = this.isAuthenticated();
    return authenticated ? (
      <Element {...rest} />
    ) : (
      <Navigate to="/login" replace />
    );
  }
}

AuthGuard.propTypes = {
  element: PropTypes.elementType.isRequired
};

export default AuthGuard;
