export default class DataTransferService {
  getBloburl() {
    return 'https://rushdev.blob.core.windows.net/images/';
  }

  setHuid(token) {
    return localStorage.setItem('hubuid', token);
  }

  setDashboardToken(DashboardToken) {
    return localStorage.setItem('dashboard-token', DashboardToken);
  }

  getDashboardToken() {
    return localStorage.getItem('dashboard-token');
  }

  setdefualtProfileImage() {
    const defaultProfileImageString =
      '7305fece-bb9f-43d0-a961-35212e1a9c7fno-profile.png';
    return this.getBloburl() + defaultProfileImageString;
  }

  logout() {
    return localStorage.clear();
  }

  getHubUid() {
    return localStorage.getItem('hubuid');
  }
}
