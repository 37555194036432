import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStatusKey } from 'components/commonComponent/commonComponents';
import { handleErrorResponse } from 'core/tokenInterceptor/AxiosInstance';
import { DashBoardService } from 'core/service/dashBoardService';

const filterEmptyValues = params => {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] !== '' && params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
};

export const SearchAPIComponent = ({
  searchTerm,
  onSearchChange,
  sort,
  status,
  onSearchResult,
  pageIndex,
  pageSize,
  service,
  loading,
  duid,
  paymentMethod
}) => {
  const dashBoardService = new DashBoardService();

  useEffect(() => {
    if (searchTerm) {
      callService(searchTerm);
    }
  }, [searchTerm, sort, status, pageIndex, pageSize, paymentMethod]);

  const callService = searchTerm => {
    loading(true);
    const params = {
      pageIndex,
      pageSize,
      status: getStatusKey(status).toUpperCase(),
      duid,
      paymentMethod,
      sort
    };

    const filteredParams = filterEmptyValues(params);
    dashBoardService[service](filteredParams, searchTerm)
      .then(response => {
        if (response.data.responseCode === 200) {
          loading(false);
          onSearchResult(response.data);
        }
      })
      .catch(error => {
        loading(false);
        handleErrorResponse(service, error.response);
        onSearchResult(null);
      });
  };

  return (
    <div className="search-container justify-content-start pointer">
      <FontAwesomeIcon icon="search" className="search-icon pointer" />
      <input
        className="search-content pointer"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={event => onSearchChange(event.target.value)}
      />
    </div>
  );
};
