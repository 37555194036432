import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ForgetPassword = () => {
  return (
    <AuthCardLayout>
      <div className="text-center">
        <h5 className="mb-0"> Forgot your password?</h5>
        <small>Enter your email and we'll send you an OTP.</small>
        <ForgetPasswordForm />
      </div>
    </AuthCardLayout>
  );
};

export default ForgetPassword;
