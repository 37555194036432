import { Appconfig } from 'core/config/app.config';
import axiosInstance from 'core/tokenInterceptor/AxiosInstance';

export class HubManagerService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'hub-manager';
  }

  postVehicles(vehicleDetailsDTO) {
    const body = JSON.stringify(vehicleDetailsDTO);
    console.log(body);
    return axiosInstance.post(this.Api + '/post-vehicle-detail', body);
  }

  activeDrivers(pageIndex, pageSize, date) {
    const params = {
      pageIndex,
      pageSize,
      date
    };
    return axiosInstance.get(this.Api + '/active-drivers', {
      params: params
    });
  }

  login(formData) {
    const body = JSON.stringify(formData);
    return axiosInstance.post(this.Api + '/login', body, {});
  }

  fetchChangeVehicleDetails(pageIndex, pageSize, changeVehicleStatus, date) {
    const params = {
      pageIndex,
      pageSize,
      changeVehicleStatus,
      date
    };
    return axiosInstance.get(this.Api + '/fetch-change-vehicle-details', {
      params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  fetchDriverRideHistory(
    duid,
    pageIndex,
    pageSize,
    rideStatus,
    paymentMethod,
    date
  ) {
    const params = {
      duid,
      pageIndex,
      pageSize,
      rideStatus,
      paymentMethod,
      date
    };

    return axiosInstance.get(this.Api + '/fetch-driver-ride-history', {
      params: params
    });
  }

  fetchHubVehicles(params) {
    return axiosInstance.get(`${this.Api}/fetch-hub-vehicles`, { params });
  }

  fetchDriverDetails(duid) {
    const params = {
      duid
    };
    return axiosInstance.get(this.Api + '/fetch-driver-details', {
      params: params
    });
  }

  updateCheckOutRequestStatus(vehicleRequestResponseDTO) {
    const body = JSON.stringify(vehicleRequestResponseDTO);
    return axiosInstance.post(
      this.Api + '/update-checkout-request-status',
      body
    );
  }

  updateChangeCabRequestStatus(vehicleRequestResponseDTO) {
    const body = JSON.stringify(vehicleRequestResponseDTO);
    return axiosInstance.post(this.Api + '/update-vehicle-change-status', body);
  }

  addDriver(driverDTO) {
    const body = JSON.stringify(driverDTO);
    return axiosInstance.post(this.Api + '/register-driver', body);
  }

  editVehicleDetail(formData) {
    return axiosInstance.post(this.Api + '/edit-vehicle-detail', formData);
  }

  fetchRideCoordinatesList(ruid) {
    const params = {
      ruid
    };
    return axiosInstance.get(this.Api + '/fetch-co-ordinates-list', {
      params: params
    });
  }

  fetchDrivers(pageIndex, pageSize) {
    const params = {
      pageIndex,
      pageSize
    };
    return axiosInstance.get(this.Api + '/fetch-hub-drivers', {
      params: params
    });
  }

  fetchHubVehiclesRideHistory(
    pageIndex,
    pageSize,
    rideStatus,
    paymentMethod,
    date
  ) {
    const params = {
      pageIndex,
      pageSize,
      date,
      rideStatus,
      paymentMethod
    };
    return axiosInstance.get(this.Api + '/fetch-hub-ride-history', {
      params: params
    });
  }

  fetchOnlineVehicles(params) {
    return axiosInstance.get(this.Api + '/fetch-online-vehicles', {
      params
    });
  }

  hubInfo() {
    return axiosInstance.get(this.Api + '/hub-info-count');
  }

  searchHubVehicles(pageIndex, pageSize, status, sort, vehicleNumber) {
    const params = {
      pageIndex,
      pageSize,
      status,
      sort
    };
    return axiosInstance.get(
      `${this.Api}/search-hub-vehicles/${vehicleNumber}`,
      {
        params: params
      }
    );
  }

  searchDrivers(duid, pageIndex, pageSize) {
    const params = {
      pageIndex,
      pageSize
    };
    return axiosInstance.get(`${this.Api}/search-driver/${duid}`, {
      params: params
    });
  }

  searchOnlineVehicles(params, vehicleNumber) {
    return axiosInstance.get(
      `${this.Api}/search-online-vehicles/${vehicleNumber}`,
      {
        params
      }
    );
  }

  searchActiveDrivers(duid, pageIndex, pageSize, date) {
    const params = {
      pageIndex,
      pageSize,
      date
    };
    return axiosInstance.get(`${this.Api}/search-active-drivers/${duid}`, {
      params: params
    });
  }

  searchDriverRideHistory(params, ruid) {
    return axiosInstance.get(this.Api + `/search-driver-ride-history/${ruid}`, {
      params
    });
  }

  searchHubRideHistory(pageIndex, pageSize, searchTerm, searchType) {
    const params = {
      pageIndex,
      pageSize,
      ruid: '',
      duid: '',
      vehicleNumber: ''
    };
    if (searchType === 'ruid') {
      params.ruid = searchTerm;
    } else if (searchType === 'duid') {
      params.duid = searchTerm;
    } else if (searchType === 'vehicleNumber') {
      params.vehicleNumber = searchTerm;
    }
    return axiosInstance.get(`${this.Api}/search-hub-ride-history`, { params });
  }

  editDriver(params, driverDTO) {
    return axiosInstance.put(`${this.Api}/edit-driver-details`, driverDTO, {
      params
    });
  }

  searchChangeVehicles(pageIndex, pageSize, vehicleRequestStatus, date, duid) {
    const params = {
      pageIndex,
      pageSize,
      vehicleRequestStatus,
      date,
      duid
    };
    return axiosInstance.get(this.Api + '/search-change-vehicles', {
      params,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}

export default HubManagerService;
