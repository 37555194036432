import React from 'react';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => (
  <AuthCardLayout>
    <Flex>
      <h5 className="fs-28 ">Account Login</h5>
    </Flex>
    <LoginForm />
  </AuthCardLayout>
);

export default Login;
