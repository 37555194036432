import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styles from './DialogBoxComponent.module.scss';
import { toast } from 'react-toastify';
import HubManagerService from 'core/service/HubManagerService';

const DialogBoxComponent = ({ selectedNotificationData, onHide }) => {
  const hubManagerService = new HubManagerService();
  const { notification } = selectedNotificationData;
  const notificationText =
    notification.notificationType === 'CHANGE_VEHICLE_REQUEST'
      ? 'has requested a Vehicle Change for'
      : 'has requested for checkout';

  const handleDeny = () => {
    if (notification.notificationType != 'CHANGE_VEHICLE_REQUEST') {
      const requestBody = generateRequestBody('REJECTED');
      updateCheckOutRequest(requestBody);
    } else {
      const requestBody = generateRequestBody('REJECTED');
      updateChangeCabRequest(requestBody);
    }
  };

  const handleApprove = () => {
    if (notification.notificationType != 'CHANGE_VEHICLE_REQUEST') {
      const requestBody = generateRequestBody('APPROVED');
      updateCheckOutRequest(requestBody);
    } else {
      const requestBody = generateRequestBody('APPROVED');
      updateChangeCabRequest(requestBody);
    }
  };

  const generateRequestBody = vehicleRequestStatus => {
    return {
      handoverCash: notification.requestedNotificationData.TotalCash,
      totalPayment: notification.requestedNotificationData.totalAmount,
      vehicleRequestStatus: vehicleRequestStatus,
      duid: notification?.driverDetails?.duid,
      vehicleEntryId: notification.vehicleEntryId,
      vehicleNumber:
        notification.notificationType === 'CHANGE_VEHICLE_REQUEST'
          ? notification.requestedNotificationData.requestedVehicleNumber
              .vehicleNumber
          : null
    };
  };

  const updateCheckOutRequest = data => {
    hubManagerService
      .updateCheckOutRequestStatus(data)
      .then(response => {
        if (response.data.responseCode === 200) {
          onHide(notification);
          toast.success(`${response.data.responseMessage}`);
        } else {
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        toast.error(`${error.response.data.responseMessage}`);
      });
  };

  const updateChangeCabRequest = data => {
    hubManagerService
      .updateChangeCabRequestStatus(data)
      .then(response => {
        if (response.data.responseCode === 200) {
          onHide(notification);
          toast.success(`${response.data.responseMessage}`);
        } else {
          toast.error(`${response.data.responseMessage}`);
        }
      })
      .catch(error => {
        console.log(error);
        toast.error(`${error.response.data.responseMessage}`);
      });
  };

  // Conditionally render the Modal based on notificationType
  if (notification.notificationType === 'CHECK_OUT_REQUESTED') {
    return (
      <div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
        >
          <div className="p-4 text-center">
            <Modal.Title id="contained-modal-title-vcenter">
              Checkout Request
            </Modal.Title>
            <div className="text-center pt-2">
              <p className={`${styles['notification-text']} m-0`}>
                {notification?.driverDetails?.firstName}{' '}
                <span>
                  ({notification?.driverDetails?.duid}) {notificationText}
                </span>
              </p>
              <p className={`${styles['notification-text']} m-0 pt-1`}>
                Cash to be Collected: ₹
                {notification.requestedNotificationData.TotalCash}
              </p>
              <p className={`${styles['notification-text']} m-0 pt-1`}>
                Total Amount: ₹
                {notification.requestedNotificationData.totalAmount}
              </p>
            </div>
            <Modal.Footer className={`${styles['modal-footer']}`}>
              <Button variant="danger" onClick={handleDeny}>
                Deny
              </Button>
              <Button variant="success" onClick={handleApprove}>
                Approve
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
        >
          <div className="text-center p-4">
            <Modal.Title id="contained-modal-title-vcenter">
              Vehicle Change Request
            </Modal.Title>
            <div className="text-center pt-2">
              <p className={`${styles['notification-text']} m-0`}>
                {notification?.driverDetails?.firstName}{' '}
                <span>
                  ({notification?.driverDetails?.duid}) {notificationText}{' '}
                  <b className={styles['name']}>
                    {
                      notification.requestedNotificationData
                        .requestedVehicleNumber.vehicleNumber
                    }
                  </b>
                </span>
              </p>
              <div>
                <img
                  src={require('./../../assets/img/vehicle-change-request.png')}
                  alt="Change Vehicle Image"
                />
              </div>
              <p
                className={`${styles['notification-text']} m-0 pt-1 d-flex justify-content-start`}
              >
                Reason: {notification.requestedNotificationData.requestedReason}
              </p>
            </div>
            <Modal.Footer className={`${styles['modal-footer']}`}>
              <Button variant="danger" onClick={handleDeny}>
                Deny
              </Button>
              <Button variant="success" onClick={handleApprove}>
                Approve
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
};

export default DialogBoxComponent;
