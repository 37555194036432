import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { DashBoardService } from 'core/service/dashBoardService';
import DataTransferService from 'core/service/dataTransferService';

const PasswordResetForm = ({ hasLabel }) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const dashBoardService = new DashBoardService();
  const dataTransferService = new DataTransferService();

  const handleSubmit = async e => {
    e.preventDefault();
    const email = sessionStorage.getItem('forgotPasswordEmail');

    if (email && !error) {
      try {
        const response = await dashBoardService.resetPassword(
          email,
          formData.confirmPassword
        );
        const { responseCode, responseMessage, token } = response.data;

        if (responseCode === 201) {
          toast.success('Password updated successfully', { theme: 'colored' });
          sessionStorage.removeItem('forgotPasswordEmail');
          dataTransferService.setDashboardToken(token);
          navigate('/');
        } else {
          toast.error(responseMessage, {
            theme: 'colored'
          });
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.responseMessage || error.message;
        toast.error(`Error resetting password: ${errorMessage}`, {
          theme: 'colored'
        });
      }
    }
  };

  const handleFieldChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'confirmPassword' && formData.password !== value) {
      setError('Passwords do not match');
    } else {
      setError('');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(prevShow => !prevShow);
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type={showPassword ? 'text' : 'password'}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type={showPassword ? 'text' : 'password'}
          isInvalid={!!error}
        />
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Show Password"
          checked={showPassword}
          onChange={toggleShowPassword}
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword || !!error}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
